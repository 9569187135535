import React from 'react';
import { LogoPropsNoColor } from './types';

type DefaultReactAttr = { className?: string; 'data-testid'?: string };

function ClubicShort({ width, height, className, 'data-testid': dataTestId }: LogoPropsNoColor & DefaultReactAttr) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 93.51 93.51"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={dataTestId}
    >
      <circle fill="#ff0037" cx="46.75" cy="46.75" r="46.75" />
      <path
        fill="var(--theme-palette-white)"
        d="M46.75,63.47a16.71,16.71,0,1,1,10-30.08A6.23,6.23,0,0,0,65,32.85l5.78-5.79a31,31,0,1,0,0,39.39L65,60.66a6.21,6.21,0,0,0-8.14-.56A16.69,16.69,0,0,1,46.75,63.47Z"
      />
    </svg>
  );
}

export default ClubicShort;
